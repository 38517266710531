<template>
  <div
    class="charts-table"
    :class="{
      'charts-table--process-reports': average,
    }"
  >
    <header class="charts-table__header">
      <p>{{ firstColumnHeader }}</p>
      <p>
        {{ $t("chartsDashboard.table.headers[0]") }}
      </p>
      <p>
        {{ $t("chartsDashboard.table.headers[1]") }}
      </p>
      <div v-if="average">
        <p>{{ $t("chartsDashboard.table.headers[2]") }}</p>
        <ElTooltip
          placement="top"
          :enterable="false"
          popper-class="charts-table__tooltip-popper el-abaca-tooltip"
        >
          <template #content>
            {{ tooltipText }}
          </template>
          <PxIcon :size="14" name="question" />
        </ElTooltip>
      </div>
    </header>
    <main ref="chartsTable" @scroll="checkTableScroll">
      <div
        v-for="(label, index) in labels"
        :key="index"
        class="charts-table__rows"
      >
        <div :title="label">
          <span
            :style="{
              backgroundColor: colors[index],
            }"
          ></span>
          <p>{{ label }}</p>
        </div>
        <p>{{ data[index] }}</p>
        <p>{{ percentages[index] + "%" }}</p>
        <p v-if="average">{{ average[index] + "%" }}</p>
      </div>
    </main>
    <div v-if="isGradientVisible" class="charts-table__gradient"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChartsTable",

  props: {
    labels: {
      type: Array as () => string[],
      required: true,
    },

    data: {
      type: Array as () => number[],
      required: true,
    },

    percentages: {
      type: Array as () => number[],
      required: true,
    },

    colors: {
      type: Array as () => string[],
      required: true,
    },

    firstColumnHeader: {
      type: String,
      required: true,
    },

    average: {
      type: Array as () => number[] | null,
      default: null,
    },
  },

  data() {
    return {
      tableScrollAtEnd: false,
      table: null as any,
      visibleElements: 5,
    };
  },

  computed: {
    tooltipText(): string {
      return this.$t("chartsDashboard.table.averageTooltip") as string;
    },

    isGradientVisible(): boolean {
      return (
        !!this.average &&
        this.data.length > this.visibleElements &&
        !this.tableScrollAtEnd
      );
    },
  },

  methods: {
    checkTableScroll() {
      this.table = this.$refs.chartsTable as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop ===
        this.table.scrollHeight - this.table.offsetHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
.charts-table {
  position: relative;

  p {
    color: #737373;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  &__rows {
    display: flex;
    flex-direction: row;

    p {
      margin: 0;
      padding: 6px 0 6px 0;
      line-height: 20px;

      text-align: right;
      color: #0a0a0a;
      font-weight: 500;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 11px;
    }

    div p {
      color: #404040;
      font-weight: 400;
      letter-spacing: -0.33px;

      max-width: calc(100% - 12px - 11px);

      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }

  &__header {
    height: 41px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid $athens-gray;

    > p:first-child {
      padding-left: 24px;
    }

    > p:not(:first-child) {
      text-align: right;
    }
  }

  &__rows div,
  &__header > p:first-child {
    width: 57.2%;
  }

  &__header p:nth-child(2),
  &__rows > p:nth-child(2) {
    width: 13.8%;
  }

  &__header p:nth-child(3),
  &__rows > p:nth-child(3) {
    width: 29%;
  }

  main {
    padding-top: 5px;
  }
}

.charts-table--process-reports {
  .charts-table__header {
    height: 39px;
    max-width: 1044px;
    margin: 0 auto;

    p {
      letter-spacing: 0.2px;
    }

    p:first-child {
      padding-left: 25px;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
    }
  }

  main {
    padding: 5px 24px 0;

    max-height: 165px;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      padding: 0 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }
  .charts-table__rows div,
  .charts-table__header > p:first-child {
    width: 41%;
  }

  .charts-table__header p:nth-child(2),
  .charts-table__rows > p:nth-child(2) {
    width: 17.9%;
  }

  .charts-table__header p:nth-child(3),
  .charts-table__rows > p:nth-child(3) {
    width: 18.3%;
  }

  .charts-table__header div,
  .charts-table__rows > p:nth-child(4) {
    width: 22.8%;
  }

  .px-icon {
    position: relative;
    bottom: 1px;

    &:hover {
      filter: brightness(0.55);
    }
  }

  .charts-table__gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(247, 249, 249, 0) 100%
    );
  }
}
</style>
<style lang="scss">
.charts-table__tooltip-popper {
  width: 247px;
  text-transform: none;
  padding: 10px 15px;
  letter-spacing: -0.2px;
}
</style>
