<template>
  <div class="matching-list page-body">
    <!-- <div class="matching-list__maintainence"> -->
    <!--   Apologies, but the My Network page will be unavailable in October. -->
    <!-- </div> -->

    <!-- <PxIcon :name="$t(icon)" size="92" /> -->

    <!-- <h2 class="matching-empty-results__title"> -->
    <!--   {{ $t(title, { targetUser }) }} -->
    <!-- </h2> -->
    <MatchingListDesktop v-if="$screen.mdUp" />
    <MatchingListMobile v-if="$screen.smDown" />
    <MatchingConfirmModal
      v-if="$user.isEntrepreneur()"
      v-model:visibility="isMatchingConfirmModalVisible"
    />
    <MatchingSupporterConfirmModal
      v-if="$user.isSupporter()"
      v-model:visibility="isMatchingConfirmModalVisible"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import isEqual from "lodash/isEqual";
import { ROUTE_MATCHING_ENTRY_POINT } from "@/modules/matching/services/router/routes-names";
import { EMetaActions, EMetaGetters } from "@/services/store/meta/meta-types";

import {
  PROFILE_META_TAB_MENU_ITEMS,
  PROFILE_META_ACTIVE_VIEW,
} from "@/modules/profile/constants";

import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";

import MatchingListDesktop from "@/modules/matching/components/matching-list-desktop/matching-list-desktop.vue";
import MatchingListMobile from "@/modules/matching/components/matching-list-mobile/matching-list-mobile.vue";

import MatchingConfirmModal from "@/modules/matching/components/matching-confirm-modal.vue";
import MatchingSupporterConfirmModal from "@/modules/matching/components/matching-supporter-confirm-modal.vue";
import { EUserMetadataGetters } from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import {
  MATCHING_ONBOARDING_COMPLETION,
  MATCHING_FROM_ONBOARDING,
  MATCHING_SELECTED_FILTERS,
  MATCHING_HAS_SYNCED_FILTERS,
} from "@/modules/matching/constants";

import { EMatchingActions } from "@/modules/matching/services/store/matching/matching.types";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { IMatchingFilters } from "@/mixins/matching-list.mixin";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { IMatchingQueryFiltersParams } from "@/modules/matching/components/matching-filters/mathing-filters.interface";
import { extractValuesFromFilter } from "@/modules/matching/components/matching-filters/matching-filters.utils";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "MatchingList",

  components: {
    MatchingListDesktop,
    MatchingListMobile,
    MatchingConfirmModal,
    MatchingSupporterConfirmModal,
  },

  props: {
    icon: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.icon",
    },
    title: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.title",
    },
  },

  data() {
    return {
      // To inform which filters were applied when fetching matches:
      selectedFilters: null as IMatchingFilters | null,
      currentlyAppliedFilters: {} as IMatchingFilters,
      isMatchingConfirmModalVisible: false,
      calculationsPolling: null as NodeJS.Timeout | null,
    };
  },

  computed: {
    authLastAssessment(): IAssessment {
      return this.$store.get("auth/latestAssessment.data");
    },

    authLatestAssessmentLevel(): ILevel | null {
      return this.authLastAssessment?.level || null;
    },

    hasMandatoryData(): boolean {
      if (this.$user.isEntrepreneur())
        return (
          this.$store.get("auth/company.data") &&
          !!this.$store.get("auth/company.data").locations.length &&
          !!this.$store.get("auth/company.data").sectors.length &&
          !!this.authLatestAssessmentLevel
        );
      if (this.$user.isSupporter())
        return (
          this.$store.get("auth/supporter.data") &&
          !!this.$store.get("auth/supporter.data").investing_level_range
        );
      else return false;
    },

    isOnBoardingCompleted(): boolean {
      return (
        // Skip matching onboarding for Supporters as requested here:
        // https://pixelmatters.atlassian.net/browse/VIR-1198
        this.$user.isSupporter() ||
        // Else, keep onboarding for Entrepreneurs:
        !!this.$store.get(
          EUserMetadataGetters.GET,
          MATCHING_ONBOARDING_COMPLETION,
        )
      );
    },

    needsOnBoarding(): boolean {
      return !this.hasMandatoryData || !this.isOnBoardingCompleted;
    },

    filtersAreSynced(): boolean {
      return (
        this.$store.getters[EMetaGetters.GET](MATCHING_HAS_SYNCED_FILTERS) ||
        false
      );
    },

    filtersByParam(): IMatchingQueryFiltersParams {
      // When fetching here, the page should be the first one
      const filters: any = {
        page: 1,
      };

      if (!this.selectedFilters) {
        return filters;
      }

      Object.entries(this.selectedFilters).forEach(([key, filter]) => {
        const filterValues = extractValuesFromFilter(filter);

        if (filterValues.length) {
          filters[key] = filterValues;
        }
      });

      return filters;
    },

    matchingParams(): IMatchingQueryFiltersParams {
      let params = {
        exclude: ["connections"],
      };

      if (this.filtersByParam) {
        params = { ...params, ...this.filtersByParam };
      }

      return params;
    },

    interestsParams(): IMatchingQueryFiltersParams {
      const params: IMatchingQueryFiltersParams = {};

      if (!this.filtersByParam || !this.selectedFilters) {
        return params;
      }

      Object.entries(this.selectedFilters).forEach(([key, filter]) => {
        if (!filter?.onlyOnDiscover && key in this.filtersByParam) {
          params[key] = this.filtersByParam[key];
        }
      });

      return params;
    },

    hasMatches(): boolean {
      return !!this.$store.state.matching.data.length || false;
    },

    isLoadingMatches(): boolean {
      return this.$store.state.matching.loading;
    },

    hasOnGoingCalculations(): boolean {
      return this.$store.get("matching/recalculating") || false;
    },

    cloneOfSelectedFilters(): IMatchingFilters | null {
      return cloneDeep(this.selectedFilters) || null;
    },
  },

  watch: {
    isMatchingConfirmModalVisible(visible: boolean) {
      if (!visible) {
        this.fetchData();
      }
    },

    cloneOfSelectedFilters: {
      immediate: true,
      deep: true,
      handler(newFilters: IMatchingFilters, oldFilters: IMatchingFilters) {
        if (
          isEqual(oldFilters, newFilters) &&
          isEqual(newFilters, this.currentlyAppliedFilters)
        ) {
          return;
        }

        const oldInterestFilters =
          oldFilters &&
          Object.values(oldFilters).filter((filter) => !filter?.onlyOnDiscover);
        const newInterestFilters =
          newFilters &&
          Object.values(newFilters).filter((filter) => !filter?.onlyOnDiscover);
        const canSkipInterests =
          !!oldInterestFilters &&
          !!newInterestFilters &&
          isEqual(oldInterestFilters, newInterestFilters);

        this.fetchData(canSkipInterests);
        // Update currently applied filters:
        this.currentlyAppliedFilters = newFilters;
      },
    },
  },

  created() {
    this.isMatchingConfirmModalVisible = this.needsOnBoarding;
    this.initOnboarding();
    this.prefillSelectedFilters();
    this.setMobileTabMenuTabs();
    this.setMobileActiveTab("discover");
    this.doubleCheckOnGoingCalculations();
  },

  unmounted() {
    this.selectedFilters = null;
    if (this.calculationsPolling) {
      clearTimeout(this.calculationsPolling);
    }
  },

  methods: {
    /**
     * Check if the matching onboarding was done right
     */
    initOnboarding() {
      const isFromStartingPoint =
        this.$store.get("meta/get", MATCHING_FROM_ONBOARDING) || false;

      if (!isFromStartingPoint && !this.isOnBoardingCompleted) {
        this.$router.replace({
          name: ROUTE_MATCHING_ENTRY_POINT,
          query: this.$route.query,
        });

        this.$store.dispatch(ECategoryActions.FETCH, {
          group: ENTREPRENEUR_USER_GROUP_ID,
        });

        this.$store.dispatch(ELevelActions.FETCH, {
          group: ENTREPRENEUR_USER_GROUP_ID,
        });

        return;
      }

      if (this.isOnBoardingCompleted && !this.isLoadingMatches) {
        this.fetchData();
      }
    },

    prefillSelectedFilters() {
      this.selectedFilters = this.$store.getters[EMetaGetters.GET](
        MATCHING_SELECTED_FILTERS,
      );
    },

    async fetchData(skipLoadingInterests = false) {
      // Ignore the data fetch when the onboarding isn't complete
      if (this.isMatchingConfirmModalVisible) {
        return;
      }

      await Promise.all([
        // Fetch Matching Discover Results
        this.$store.dispatch(EMatchingActions.FETCH, this.matchingParams),
        // Fetch Matching Recalculations state
        this.$store.dispatch(EMatchingActions.FETCH_RECALCULATIONS_STATE),
      ]);

      // Fetch Matching Interests - User, Target and Mutual Interests
      if (!skipLoadingInterests) {
        this.$store.dispatch(
          EMatchingInterestActions.FETCH_ALL,
          this.interestsParams,
        );
      }
    },

    /**
     * Sometimes the API takes a couple seconds/miliseconds more
     * to signal the begining of ongoing calculations so this
     * method aims at trying to prevent us from asking for
     * the recalculations state too early.
     */
    doubleCheckOnGoingCalculations(waitPeriod = 5000) {
      if (this.hasOnGoingCalculations) return;

      this.calculationsPolling = setTimeout(() => {
        this.$store.dispatch(EMatchingActions.FETCH_RECALCULATIONS_STATE);
      }, waitPeriod);
    },

    /**
     * Define the items for the mobile tabs menu.
     */
    setMobileTabMenuTabs() {
      const items = [
        {
          key: "discover",
          label: this.$t("matching.matchingList.tabs.discoverMobile"),
          to: ROUTE_MATCHING_LIST,
        },
      ];

      if (this.$features.isEnabled("matchingInterest")) {
        items.push(
          {
            key: "pending",
            label: this.$t("matching.matchingList.tabs.pendingMobile"),
            to: ROUTE_MATCHING_LIST,
          },
          {
            key: "connected",
            label: this.$t("matching.matchingList.tabs.connected"),
            to: ROUTE_MATCHING_LIST,
          },
        );
      }

      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_TAB_MENU_ITEMS,
        value: items,
      });
    },

    setMobileActiveTab(tab: string) {
      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_ACTIVE_VIEW,
        value: tab,
      });
    },
  },
});
</script>

<style lang="scss">
.matching-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__maintainence {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f6a34b;
    padding: 32px;
    width: 100%;
    border-bottom: 2px solid #c7853e;
    color: #f0f1f5;
    text-align: center;
  }
}
</style>
