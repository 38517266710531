import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-250cda70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submissions-panel__table" }
const _hoisted_2 = {
  key: 0,
  class: "submissions-panel__gradient"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxListHeader = _resolveComponent("PxListHeader")!
  const _component_PxTableHeader = _resolveComponent("PxTableHeader")!
  const _component_SubmissionsTableRowPlaceholder = _resolveComponent("SubmissionsTableRowPlaceholder")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxEmptyMessage = _resolveComponent("PxEmptyMessage")!
  const _component_SubmissionsTableRow = _resolveComponent("SubmissionsTableRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'submissions-panel__owner-submissions-view': !_ctx.isUserSubmissionsView,
      'submissions-panel-empty': !_ctx.hasSubmissions && !_ctx.isLoading,
    }, "submissions-panel"])
  }, [
    _createVNode(_component_PxListHeader, {
      "badge-number": _ctx.numberOfSubmissions,
      title: _ctx.tableTitle
    }, null, 8, ["badge-number", "title"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showTableHeader)
        ? (_openBlock(), _createBlock(_component_PxTableHeader, {
            key: 0,
            items: _ctx.tableHeaders,
            class: "submissions-panel__table-header"
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.numberOfLoadingPlaceholders, (placeholder, index) => {
            return (_openBlock(), _createBlock(_component_SubmissionsTableRowPlaceholder, {
              key: index,
              "user-view": _ctx.isUserSubmissionsView,
              class: "submissions-panel__table-row"
            }, null, 8, ["user-view"]))
          }), 128))
        : (!_ctx.hasSubmissions && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_PxEmptyMessage, {
              key: 2,
              icon: _ctx.emptyCopy.icon ? _ctx.emptyCopy.icon : 'lists-empty-state',
              "icon-size": "auto",
              subtitle: _ctx.emptyCopy.subtitle,
              "subtitle-size": 15,
              title: _ctx.emptyCopy.title,
              "title-size": 17,
              class: "submissions-panel-empty__message",
              "subtitle-style": "normal"
            }, {
              action: _withCtx(() => [
                (_ctx.emptyCopy.action)
                  ? (_openBlock(), _createBlock(_component_PxButton, {
                      key: 0,
                      label: _ctx.emptyCopy.action,
                      class: "submissions-panel-empty__action",
                      type: "link",
                      onClick: _ctx.goToProgramsPage
                    }, null, 8, ["label", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["icon", "subtitle", "title"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 3,
              ref: "table",
              style: _normalizeStyle({
          minHeight: _ctx.$refs.table
            ? (_ctx.$refs.table as any).offsetHeight + 'px'
            : '0px',
        }),
              class: "submissions-panel__table-rows",
              onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkTableScroll && _ctx.checkTableScroll(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.submissionsData, (submission, index) => {
                return (_openBlock(), _createBlock(_component_SubmissionsTableRow, {
                  key: index,
                  "is-user-submissions-view": _ctx.isUserSubmissionsView,
                  "submission-data": submission,
                  type: _ctx.type,
                  class: "submissions-panel__table-row"
                }, null, 8, ["is-user-submissions-view", "submission-data", "type"]))
              }), 128))
            ], 36))
    ]),
    (_ctx.isGradientVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}