import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9023cc3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-members-list" }
const _hoisted_2 = {
  key: 0,
  class: "team-members-list__gradient"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxListHeader = _resolveComponent("PxListHeader")!
  const _component_TeamMembersListMemberEntry = _resolveComponent("TeamMembersListMemberEntry")!
  const _component_TeamMembersListFooter = _resolveComponent("TeamMembersListFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxListHeader, {
      "badge-number": _ctx.membersList.length,
      title: _ctx.listHeaderTitle
    }, null, 8, ["badge-number", "title"]),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createElementVNode("div", {
        ref: "membersList",
        class: "team-members-list__members",
        onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkListScroll && _ctx.checkListScroll(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.membersList, (member, index) => {
          return (_openBlock(), _createBlock(_component_TeamMembersListMemberEntry, {
            key: index,
            "is-selected": _ctx.selectedMemberIndex === index,
            member: member,
            "show-remove-button": _ctx.membersList.length > 1,
            onClick: _withModifiers(($event: any) => (_ctx.updateSelectedMember(index)), ["stop"]),
            onRemoveMember: ($event: any) => (_ctx.removeMember(index))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" > ")
            ]),
            _: 2
          }, 1032, ["is-selected", "member", "show-remove-button", "onClick", "onRemoveMember"]))
        }), 128))
      ], 544),
      (_ctx.isGradientVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, _ctx.isLoading]
    ]),
    _createVNode(_component_TeamMembersListFooter, {
      disabled: _ctx.isLoading || _ctx.formInvalid,
      class: "team-members-list__footer",
      onAddMember: _ctx.addMember
    }, null, 8, ["disabled", "onAddMember"])
  ]))
}