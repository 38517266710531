<template>
  <template v-if="props.type === 'bar' && chartState === 'ready'">
    <Bar
      id="my-chart-id-1"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-style="chartStyles"
      :inner-height="600"
      :plugins="chartPlugins"
    />
  </template>
  <!-- <template v-if="props.type === 'line' && chartState === 'ready'">
    <Line
      id="my-chart-id-2"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-style="chartStyles"
      :inner-height="600"
      :plugins="chartPlugins"
    />
  </template> -->
  <div v-if="chartState === 'invalid'" class="vil-barchart--invalid">
    <p>Invalid Chart Data</p>
  </div>
  <div v-if="chartState === 'rendering'" class="vil-barchart--rendering">
    <p>Rendering ...</p>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { watch } from "vue";
import { defineProps } from "vue";
import { onBeforeMount } from "vue";

import { Bar } from "vue-chartjs";
// import { Line } from "vue-chartjs";

import { Title } from "chart.js";
import { Legend } from "chart.js";
import { Tooltip } from "chart.js";
import { LinearScale } from "chart.js";
import { TimeScale } from "chart.js";
import { LineElement } from "chart.js";
import { PointElement } from "chart.js";
import { CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js";

import { fatArrow } from "./plugins/fatArrowBarChart";
// import { ObjectUtils } from "@/utils/object";
import { PX_CUSTOM_CHART_PLUGINS } from "./types";

import type { TChartData } from "vue-chartjs/dist/types";
import type { TChartOptions } from "vue-chartjs/dist/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
);

type PxCustomChartPlugin = {
  id: PX_CUSTOM_CHART_PLUGINS;
  options: Record<string, any>;
};

enum PxCustomChartType {
  BAR = "bar",
  LINE = "line",
}

const props = defineProps<{
  type: PxCustomChartType;
  data: TChartData<PxCustomChartType>;
  styles: Record<string, any>;
  options: Record<string, any>;
  plugins: PxCustomChartPlugin[];
}>();

/**
 * options: {
 *  barType: "ARROW", // ARROW | BLOCK,
 *  displayType: "NORMAL" // NORMAL | SEGRAGATED
 * }
 */
const pluginMap = {
  [PX_CUSTOM_CHART_PLUGINS.FAT_ARROW]: fatArrow,
};
const defaultStyles = { height: "530px" };
let defaultOptions: Record<string, any> = {
  responsive: true,
  animation: false,
  maintainAspectRatio: false,
  hover: {
    mode: "point",
    axis: "x",
    includeInvisible: true,
    intersect: true,
  },
  scales: {},
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        pointStyle: "circle",
        usePointStyle: true,
      },
    },
  },
};

const chartState = ref("rendering");
const chartData = ref<any>({});
const chartStyles = ref({});
const chartPlugins = ref<any>([]);
const chartOptions = ref<any>();

const getPlugins = (plugin: PxCustomChartPlugin[]) => {
  const selectedPlugins = [];

  if (plugin) {
    for (const key in plugin) {
      selectedPlugins.push(pluginMap[plugin[key].id]);
    }
  }

  return selectedPlugins;
};

const initialize = () => {
  chartState.value = "rendering";

  if (!props.data) {
    chartState.value = "invalid";
    return;
  }

  chartData.value = props.data;
  chartStyles.value = defaultStyles;
  chartPlugins.value = getPlugins(props.plugins);

  if (props.plugins) {
    for (const plugin of props.plugins) {
      if (plugin.options) {
        defaultOptions.plugins[String(plugin.id)] = plugin.options;
      }
    }
  }

  if (props.options?.scales) {
    defaultOptions.scales = props.options.scales;
  }

  if (props.type === "bar") {
    chartOptions.value = defaultOptions as TChartOptions<"bar">;
  }
  if (props.type === "line") {
    chartOptions.value = defaultOptions as TChartOptions<"line">;
  }

  chartState.value = "ready";
};

onBeforeMount(initialize);
watch(() => props.data, initialize);
</script>

<style lang="scss" scoped>
.vil-barchart--invalid,
.vil-barchart--rendering {
  width: 100%;
  height: 530px;

  background: repeating-linear-gradient(
    -60deg,
    #fff,
    #fff 40px,
    rgba(226, 226, 226, 0.15) 40px,
    rgba(226, 226, 226, 0.15) 100px
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
