<template>
  <div class="charts-table charts-comparison-table">
    <colgroup>
      <col span="1" />
      <col span="5" />
    </colgroup>
    <header class="charts-comparison-table__header">
      <p v-for="(header, index) in headers" :key="index">
        {{ header.title }}
        <ElTooltip
          v-if="header.tooltip"
          placement="top"
          :enterable="false"
          popper-class="charts-comparison-table__tooltip-popper el-abaca-tooltip"
        >
          <template #content>
            {{ header.tooltip }}
          </template>
          <PxIcon :size="14" name="question" />
        </ElTooltip>
      </p>
    </header>
    <main ref="chartsTable" @scroll="checkTableScroll">
      <div
        v-for="(label, index) in labels"
        :key="index"
        class="charts-comparison-table__rows"
      >
        <div :title="label">
          <span
            :style="{
              backgroundColor: colors[index],
            }"
          ></span>
          <p>{{ label }}</p>
        </div>
        <p>
          {{ data[index].absoluteValues[0] }}
        </p>
        <p>{{ data[index].data[0] }}%</p>
        <p>
          {{
            data[index].absoluteValues[1] >= 0
              ? data[index].absoluteValues[1]
              : "-"
          }}
        </p>
        <p>{{ data[index].data[1] >= 0 ? `${data[index].data[1]}%` : "-" }}</p>
        <p v-if="data[0].absoluteValues.length <= 1">{{ "-" }}</p>
        <p
          v-else
          class="charts-comparison-table__change"
          v-html="getDifference(data[index].data[1], data[index].data[0])"
        ></p>
      </div>
    </main>
    <div
      v-if="isGradientVisible"
      class="charts-comparison-table__gradient"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IBarChartDataset } from "@/modules/charts-dashboard/components/process-reports/process-reports-chart.vue";

export interface IChartsComparisonTableHeader {
  title: string;
  tooltip?: string;
}

export default defineComponent({
  name: "ChartsComparisonTable",

  props: {
    headers: {
      type: Array as () => IChartsComparisonTableHeader[],
      required: true,
    },

    labels: {
      type: Array as () => string[],
      required: true,
    },

    colors: {
      type: Array as () => string[],
      required: true,
    },

    data: {
      type: Array as () => IBarChartDataset[],
      required: true,
    },
  },

  data() {
    return {
      tableScrollAtEnd: false,
      table: null as any,
      visibleElements: 5,
    };
  },

  computed: {
    tooltipText(): string {
      return this.$t("chartsDashboard.table.averageTooltip") as string;
    },

    isGradientVisible(): boolean {
      return this.data.length > this.visibleElements && !this.tableScrollAtEnd;
    },
  },

  methods: {
    checkTableScroll() {
      this.table = this.$refs.chartsTable as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop ===
        this.table.scrollHeight - this.table.offsetHeight;
    },

    getDifference(firstValue: number, secondValue: number): string {
      if (firstValue === secondValue) return "0%";

      const classToAdd = firstValue > secondValue ? "positive" : "negative";

      return `<span class="${classToAdd}">${Math.abs(
        firstValue - secondValue,
      ).toFixed(1)}%</span>`;
    },
  },
});
</script>

<style lang="scss" scoped>
.charts-comparison-table {
  position: relative;

  p {
    margin: 0;

    color: #737373;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  p.charts-comparison-table__change {
    font-weight: 600;
  }

  &__header,
  &__rows {
    display: grid;
    grid-template-columns:
      minmax(0, 400px) repeat(4, minmax(min-content, 150px))
      repeat(1, minmax(0, 150px));
  }

  &__header p {
    display: flex;
    align-items: center;

    gap: 6px;

    letter-spacing: 0.2px;
  }

  &__rows p {
    padding: 6px 0 6px 0;
    line-height: 20px;

    text-transform: capitalize;
    text-align: right;
    color: #0a0a0a;
    font-weight: 500;
  }

  &__rows div p {
    color: #404040;
    font-weight: 400;
    letter-spacing: -0.33px;

    max-width: calc(100% - 12px - 11px);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__header > p:first-child {
    padding-left: 25px;
  }

  &__rows {
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 11px;
    }

    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }

  &__header {
    height: 39px;
    max-width: 1044px;
    margin: 0 auto;

    border-bottom: 1px solid $athens-gray;

    p:not(:first-child) {
      justify-content: flex-end;
    }

    .px-icon {
      position: relative;

      &:hover {
        filter: brightness(0.55);
      }
    }
  }

  main {
    padding: 5px 24px 0;

    max-height: 165px;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      padding: 0 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__change :deep() {
    span {
      font-weight: 600;
      position: relative;
      padding-left: 19px;
      line-height: 1;
    }

    span::before {
      content: "";
      position: absolute;
      top: 9px;
      left: 7px;

      width: 0;
      height: 0;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    .negative {
      color: $wild-watermelon;
    }

    .negative::before {
      border-top: 5px solid $wild-watermelon;
    }
    .positive {
      color: $fern;
    }

    .positive::before {
      border-bottom: 5px solid $fern;
    }
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(247, 249, 249, 0) 100%
    );
  }
}
</style>
<style lang="scss">
.charts-comparison-table__tooltip-popper {
  width: 350px;
  text-transform: none;
  padding: 10px 15px;
  letter-spacing: -0.2px;
}
</style>
